import { motion } from "framer-motion";
import React, { useContext } from "react";
import appContext from "utils/app-context";

type Props = {
  close: () => void;
};

const HomeAbout = ({ close }: Props) => {
  const {locale} = useContext(appContext) 
  if(!locale) return null
  const text = locale.home
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      className="home-about"
    >
      <button className="close" onClick={close} />
      <div dangerouslySetInnerHTML={{__html:text.about_html}}/>
      <div>
        <button className="main-action__btn" onClick={close}>
          {text.close}
        </button>
      </div>
    </motion.div>
  );
};

export default HomeAbout;

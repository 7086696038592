import { Stone } from "types/user-data";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id || 1745939501;
interface Responce {
  result: boolean;
  balance: number;
  available_games: number;
}

export default async (
  addition_coins: number,
  asteroids: number,
  coins: number,
  rs: number,
  stones: Partial<Record<Stone["alias"], number>>
): Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "finish_game";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("addition_coins", String(addition_coins));
    API_URL.searchParams.set("asteroids", String(asteroids));
    API_URL.searchParams.set("coins", String(coins));
    API_URL.searchParams.set("rs", String(rs));

    const aliases = Object.keys(stones) as Stone["alias"][];

    for (const alias of aliases) {
      API_URL.searchParams.set(alias, String(stones[alias]));
    }

    const responce = await fetch(API_URL);
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};

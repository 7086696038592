import { ItemType } from "types/grid-item";

export default (StreakLength: number,type:ItemType) => {
  if(type === 'type1') return 0
  switch (true) {
    case StreakLength === 3:
      return 300;
    case StreakLength === 4:
      return 500;
    case StreakLength === 5:
      return 800;
    case StreakLength === 6:
      return 1200;
    case StreakLength > 6:
      return 1200;
    default:
      break;
  }
  return 300
};

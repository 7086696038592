import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import Button from "./Button";
import Icon from "./Icon";
import appContext from "utils/app-context";
import { bgVariants, bodyVariants, bottomBodyVariants } from "utils/modal-animation";

type Props = {};

const Modal = (props: Props) => {
  const { modal, setModal } = useContext(appContext);

  return (
    <AnimatePresence>
      {modal && (
        <motion.div
          {...bgVariants}
          onClick={() => setModal(null)}
          className="modal__bg modal-bg _close-modal"
        >
          <motion.div {...bottomBodyVariants} className="modal__dialog" onClick={(e) => e.stopPropagation()}>
            <div className="m-card">
                <div className="m-card__inner">
                    {modal.content}
                </div>
            </div>
        </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};



export default Modal;

import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PageWrapper from 'UI/PageWrapper';
import i1 from 'assets/images/arrow-top3.svg';
import i2 from 'assets/images/flash.svg';
import i3 from 'assets/images/flash2.svg';
import i4 from 'assets/images/line.svg';
import i5 from 'assets/images/placeholder-race.png';
import i6 from 'assets/images/placeholder-teams.png';

const link = new URL(process.env.REACT_APP_SHARE_LINK || '');
link.searchParams.delete('start');

const urlsToLoad = [i1, i2, i3, i4, i5, i6,'img/training/1.png','img/training/4.png','img/training/3.png','img/training/2.png'];

const Loading = () => {
  const [loading, setLoading] = useState(0); // Общий прогресс
  console.log("🚀 ~ Loading ~ loading:", loading)
  const [currentIndex, setCurrentIndex] = useState(0); // Индекс текущего URL
  const [allowed, setAllowed] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false); // Проверка загрузки DOM
  const navigate = useNavigate();

  useEffect(() => {
    const handleDomContentLoaded = () => {
      setDomLoaded(true);
    };

    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      handleDomContentLoaded();
    } else {
      window.addEventListener('DOMContentLoaded', handleDomContentLoaded);
    }

    return () => {
      window.removeEventListener('DOMContentLoaded', handleDomContentLoaded);
    };
  }, []);

  useEffect(() => {
    if (!allowed || !domLoaded) return;

    const loadUrlSequentially = async () => {
      for (let i = currentIndex; i < urlsToLoad.length; i++) {
        try {
          const response = await fetch(urlsToLoad[i]);
          if (!response.ok) throw new Error(`Failed to load ${urlsToLoad[i]}`);
          // Обновляем прогресс
          setLoading(((i + 1) / urlsToLoad.length) * 100);
          setCurrentIndex(i + 1);
        } catch (error) {
          console.error(error);
          break;
        }
      }

      if (currentIndex >= urlsToLoad.length - 1) {
        const training = localStorage.getItem('training')
        setTimeout(() => {
          navigate(training ? '/home' : '/training',{replace:true});
        }, 1000);
      }
    };

    loadUrlSequentially();
  }, [allowed, currentIndex, navigate, domLoaded]);

  useEffect(() => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) setAllowed(true);
  }, []);

  return (
    <PageWrapper className="m-page" style={{ overflow: 'hidden' }}>
      <div className="preloader">
        <img src="img/logo.svg" alt="" className="preloader__logo" />
        <div className="preloader__content">
          <img
            src="img/preloader.jpg"
            alt=""
            className={`preloader__img ${!allowed && 'qr-showed'}`}
          />
          {!allowed && (
            <div className="qr-container">
              <QRCodeSVG
                value={link.toString()}
                size={170}
                level="Q"
                bgColor="#00000000"
                fgColor="#d50013"
                marginSize={4}
              />
            </div>
          )}
          {allowed && (
            <div className="loader">
              <div className="loader__num">
                Loading...{' '}
                <span id="percent">{Math.round(loading)}</span>%
              </div>
              <div className="loader__progress">
                <span
                  id="percent-progress"
                  style={{ width: `${loading}%` }}
                ></span>
              </div>
            </div>
          )}
        </div>
        <div className="preloader__bottom">
          <div className="preloader__text text14">
            {allowed
              ? 'Official channels by Proof Ranking'
              : 'Play Race of Stone on your mobile device!'}
          </div>
          <div className="preloader__wrap">
            <img src="img/logo2.svg" alt="" className="preloader__logo2" />
            <a href="https://t.me/RaceOfStone" className="preloader__link">
              <img src="img/youtube.svg" alt="" />
            </a>
            <a href="https://t.me/RaceOfStone" className="preloader__link">
              <img src="img/tg.svg" alt="" />
            </a>
            <a href="https://t.me/RaceOfStone" className="preloader__link">
              <img src="img/x.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Loading;

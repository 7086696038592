import { Dispatch, SetStateAction } from "react";
import AppContextType from "types/game-context";
import { Stone } from "types/user-data";

export default (setStones:Dispatch<SetStateAction<Stone[] | null>>,selectedStone:Stone,stoneCounter:AppContextType['stonesCounter']) => {
    setStones(prev => prev ? prev.map(stone => {
        if(stone.alias === selectedStone.alias) stone.quantity--
        return stone
      }) : prev)
      stoneCounter[selectedStone.alias] = (stoneCounter[selectedStone.alias] || 0) - 1
      // stoneAction(selectedStone.alias)
}
import { useState, useEffect, useContext } from 'react';
import UserData from 'types/user-data';
import appContext from 'utils/app-context';

export default (userData: UserData | null) => {
    const {updateUserInfo} = useContext(appContext)
    const [seconds, setSeconds] = useState(0);
    const [percent, setPercent] = useState(0);
    const [coins, setCoins] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (userData && Number(userData.farming_status) === 1) {
            const { farming_seconds, farming_period, farming_total } = userData;

            setSeconds(farming_seconds);

            // Процент прогресса рассчитывается на основе оставшихся секунд и общего времени фарминга
            const initialPercent = ((farming_period - farming_seconds) / farming_period) * 100;
            setPercent(initialPercent);

            // Инициализация монет (заработанных к текущему моменту)
            const initialCoins = ((farming_period - farming_seconds) / farming_period) * farming_total;
            setCoins(initialCoins);

            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    const newSeconds = Math.max(prevSeconds - 0.25, 0);

                    // Обновляем процент прогресса
                    setPercent(((farming_period - newSeconds) / farming_period) * 100);

                    // Обновляем количество монет
                    setCoins(((farming_period - newSeconds) / farming_period) * farming_total);

                    // Останавливаем таймер, если время истекло
                    if (newSeconds <= 0) {
                        clearInterval(interval!);
                        updateUserInfo()
                    }

                    return newSeconds;
                });
            }, 250);
        }

        if (userData && Number(userData.farming_status) === 2) {
            // Если фарминг завершен, устанавливаем прогресс на 100% и максимальное количество монет
            setPercent(100);
            setCoins(userData.farming_total);
        }

        // Очищаем интервал при завершении или размонтировании компонента
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [userData]);

    return { seconds, percent, coins };
};

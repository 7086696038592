import { useEffect, useRef, useState } from "react";

const useTime = (initialTime: number, autoStart: boolean = true) => {
  const [time, setTime] = useState(initialTime);
  const [isPaused, setIsPaused] = useState<number | boolean>(!autoStart ? true : false); // Либо true, либо секунды, либо false
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const pauseTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Для отслеживания паузы

  useEffect(() => {
    if (autoStart) {
      startTimer(); // Запускаем таймер автоматически, если указано
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (pauseTimeoutRef.current) {
        clearTimeout(pauseTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Если isPaused === false, запускаем таймер
    if (isPaused === false) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 0) {
            return prevTime; // Таймер остановится, когда достигнет 0
          }
          return prevTime - 1000; // Уменьшаем время на 1 секунду
        });
      }, 1000);
    }

    // Если isPaused — это число, уменьшаем его каждую секунду, пока не вернемся к false
    if (typeof isPaused === "number") {
      const pauseInterval = setInterval(() => {
        setIsPaused((prev) => (typeof prev === "number" && prev > 1 ? prev - 1 : false));
      }, 1000);

      return () => clearInterval(pauseInterval);
    }

    // Если isPaused === true, таймер не работает, ждем, пока его снимут с паузы вручную
    if (isPaused === true) {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [isPaused]);

  const startTimer = (seconds?: number) => {
    if (seconds) setTime(seconds);
    setIsPaused(false); // Снимаем с паузы
  };

  const pauseTimer = (seconds?: number) => {
    // Если передали число секунд — ставим таймер на паузу на указанное время
    if (typeof seconds === "number") {
      setIsPaused(seconds);

      if (intervalRef.current) clearInterval(intervalRef.current);
      
      pauseTimeoutRef.current = setTimeout(() => {
        setIsPaused(false); // Снимаем с паузы после окончания
        startTimer(); // Возобновляем таймер
      }, seconds * 1000);
    } else {
      setIsPaused(true); // Бесконечная пауза
      if (intervalRef.current) clearInterval(intervalRef.current); // Останавливаем таймер
    }
  };

  const addTime = (time:number) => {
    setTime(prev => prev + time)
  }

  return { time, isPaused, startTimer, pauseTimer, setIsPaused ,addTime};
};

export default useTime;

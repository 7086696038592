import tg from "utils/tg";
import UserData from "types/user-data";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
interface Response {
  success: number;
  current_stage: number;
  balance: number;
}
export default async ():Promise<Response | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "everyday_task_done";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
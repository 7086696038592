import doneTask from "api/done-task";
import React, { useContext, useState } from "react";
import Task from "types/task";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import tg from "utils/tg";

type Props = {
  task: Task;
  handleClick: () => void;
};

const TaskCard = ({
  task: { reward, status, title, id ,url},
  handleClick,
}: Props) => {
    const [pending, setpending] = useState(false)
    const {updateUserInfo} = useContext(appContext)
    const handleTask = async () => {
        setpending(true)
        if(url) {
            try {
                tg.openTelegramLink(url);
              } catch (error) {
                tg.openLink(url);
              }
            }          
          setTimeout(async () => {
            await doneTask(id)
            await handleClick()
            updateUserInfo()
            setpending(false)
          }, 3000);
    }
    
  let taskBtn = (
    <button
      className="task-card__btn m-btn m-btn-red"
      onClick={handleTask}
    >
      <span>GO</span>
    </button>
  );
  switch (status) {
    case 1:
      taskBtn = (
        <div className="task-card__btn task-card__btn2 m-btn">
          <img src="img/check.svg" alt="" />
        </div>
      );
      break;

    case 2:
      taskBtn = (
        <div className="task-card__btn m-btn m-btn-red">
          <img className="loading-icon" src="img/loader.svg" alt="" />
        </div>
      );
      break;
  }
  if(pending) taskBtn = (
    <div className="task-card__btn m-btn m-btn-red">
          <img className="loading-icon" src="img/loader.svg" alt="" />
        </div>
  );
  return (
    <div className="m-card task-card">
      <div className="m-card__inner">
        <div className="task-card__wrap">
          <div className="task-card__items">
            <div className="task-card__item">
              <div className="task-card__icon">
                <img src="img/task-icon.svg" alt="" />
              </div>
              <span className="text14 bold-text">{title}</span>
            </div>
            <div className="task-card__item">
              <div className="task-card__icon">
                <img src="img/coin.svg" alt="" />
              </div>
              <span className="text14">+{formatNumber(reward)}</span>
            </div>
          </div>
          {taskBtn}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;

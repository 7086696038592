import React, { useContext } from 'react';
import useDimensions from 'hooks/use-dimensions';
import useGame from 'hooks/use-game';
import { AnimatePresence } from 'framer-motion';
import GridLines from './GridLines';
import gameContext from 'utils/game-context';

type Props = {
}

const GameField = (props: Props) => {
  const { ref, dimensions } = useDimensions();
  const {selectedStone} = useContext(gameContext)

  const gridColumns = 6;
  const gridRows = 8;
  const cellSize = Math.min(dimensions.width / gridColumns, dimensions.height / gridRows);

  const { showCells } = useGame(gridRows, gridColumns, cellSize);
  const fieldWidth = cellSize * gridColumns;
  const fieldHeight = cellSize * gridRows;

  return (
    <div
      ref={ref}
      className='game-field-container'
      style={dimensions.height ? {height:fieldHeight} : {}}
    >
      <div
        className={`game-field ${selectedStone ? 'active' : ''}`}
        style={{
          position: 'relative',
          width: `${fieldWidth}px`,
          height: `${fieldHeight}px`,
        }}
      >
        <GridLines cellSize={cellSize} columns={gridColumns} rows={gridRows}/>
        <AnimatePresence mode='sync' initial={false}>
          {showCells()}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default GameField;

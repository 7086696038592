import { PointerEventHandler, TouchEventHandler, useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import GridItem, { ItemType } from '../types/grid-item';
import appContext from 'utils/game-context';
import stonesInfo from 'utils/stones-info';
import rewardByStreak from 'utils/reward-by-streak';
import stoneUse from 'utils/stone-use';
import { Stone } from 'types/user-data';
import AsteroidExplosionResult from 'types/asteroid-explosion-result';
import generateInitalGrid, { generateId, getRandomItem } from 'utils/generate-inital-grid';
import tg from 'utils/tg';

interface Match {
  itemsToDelete: GridItem[];
  reward: number;
  combo: number;
  center: { row: number, col: number };
}

const useGame = (rows: number, columns: number, cellSize: number) => {

  const {
    selectedStone,
    setselectedStone,
    setstones,
    spawnAnimatedReward,
    setCoins,
    setCombo,
    gameState,
    setgameState,
    startTimer,
    popAsteroidExplosions,
    stonesCounter,
    addTime
  } = useContext(appContext)

  const [grid, setGrid] = useState<GridItem[][]>(generateInitalGrid(columns,rows));
  const [blocked, setblocked] = useState(false)
  const [selectedCell, setSelectedCell] = useState<{ row: number; col: number } | null>(null);
  const [startTouch, setStartTouch] = useState<{ x: number; y: number } | null>(null);
  const [isSwiping, setIsSwiping] = useState(false);

  const handleTouchStart = (row: number, col: number,type:ItemType) => {
    if(blocked) return
    console.log('TOUCH START');
    
    if(gameState !== 'game'){
      setgameState('game')
      startTimer()
    }
    
    if (isSwiping) return;
    if (selectedStone) {
      const stoneInfo = stonesInfo[selectedStone.alias]
      setGrid(stoneInfo.destroyCells(row,col,grid,type))
      stoneUse(setstones,selectedStone,stonesCounter)
      setselectedStone(null)
      return
    }
    setSelectedCell({ row, col });
    setStartTouch(null);
  };

  const handleTouchMove = (clientX: number, clientY: number) => {
    if (!selectedCell || isSwiping || blocked) return;

    // const touch = e.clientX

    if (!startTouch) {
      setStartTouch({ x: clientX, y: clientY });
      return;
    }
    console.log('TOUCH MOVE');

    const dx = clientX - startTouch.x;
    const dy = clientY - startTouch.y;

    const threshold = 30;

    if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > threshold) {
      const newCol = selectedCell.col + (dx > 0 ? 1 : -1);
      if (newCol >= 0 && newCol < columns) {
        handleSwap(selectedCell.row, selectedCell.col, selectedCell.row, newCol);
        setIsSwiping(true);
      }
    } else if (Math.abs(dy) > Math.abs(dx) && Math.abs(dy) > threshold) {
      const newRow = selectedCell.row + (dy > 0 ? 1 : -1);
      if (newRow >= 0 && newRow < rows) {
        handleSwap(selectedCell.row, selectedCell.col, newRow, selectedCell.col);
        setIsSwiping(true);
      }
    }
  };

  const handleTouchEnd = () => {
    setStartTouch(null);
    if (isSwiping) {
      setIsSwiping(false);
      setSelectedCell(null);
    }
    console.log('TOUCH END');
  };

  const handleSwap = (fromRow: number, fromCol: number, toRow: number, toCol: number) => {
    console.log('SWAP!');
    if(blocked) return
    setblocked(true)
    const newGrid = grid.map((row) => row.map((cell) => ({ ...cell })));
    
    // Выполняем замену
    const fromItem = newGrid[fromRow][fromCol];
    const toItem = newGrid[toRow][toCol];
    newGrid[fromRow][fromCol] = { ...toItem };
    newGrid[toRow][toCol] = { ...fromItem };
  
    setGrid(newGrid);
  
    // Анимация должна завершиться до проверки совпадений
    setTimeout(() => {
      const matches = findMatches(newGrid);
      // // Обновляем счётчики монет и комбо
      
    
      // // Обрабатываем анимацию взрыва астероидов
      // handleAsteroidExposion(matches);
    
      if (matches.length > 0) {
        handleRemoveCells(matches,newGrid)
      } else {
        // Нет совпадений — возвращаем элементы на места
        const revertedGrid = [...grid];
        revertedGrid[fromRow][fromCol] = fromItem;
        revertedGrid[toRow][toCol] = toItem;
        setGrid(revertedGrid);
        setTimeout(() => {
          setblocked(false)
        }, 260);
      }
    }, 250); // Время, равное длительности анимации
    
  };

  function handleRemoveCells (matches:Match[],grid:GridItem[][]) {
    setblocked(true)
    console.log('REMOVE CELLS!');
    
    
    // Есть совпадения — помечаем предметы для удаления и указываем центральную точку
    const updatedGrid = grid.map(row =>
      row.map(cell => {
        const match = matches.find(match => match.itemsToDelete.includes(cell));
        if (match) {
          return {
            ...cell,
            toBeDeleted: true, // Флаг для удаления
            center: match.center, // Центральная точка для смещения
          };
        }
        return cell;
      })
    );

    // Обновляем сетку с флагами удаления и центральными точками
    setGrid(updatedGrid as GridItem[][]);

    const allTypes1 = matches
    .reduce((arr: GridItem[], { itemsToDelete: i }) => arr.concat(i), [])
    .filter(({ type }) => type === 'type1')
    .reduce((acc: GridItem[], item) => {
      if (!acc.some(({ id }) => id === item.id)) {
        acc.push(item);
      }
      return acc;
    }, []);

    const duplicates = new Set()

    const asteroidExplosionsResults = popAsteroidExplosions(allTypes1.length)
    matches.forEach(match => {
      const { row, col } = match.center;
  
      // Получаем элемент ячейки из DOM
      const gridCellElement = document.querySelector(`.row-${row}.col-${col}`);
      if (gridCellElement) {
        const rect = gridCellElement.getBoundingClientRect();
  
        // Вычисляем абсолютную позицию центра ячейки
        const position = {
          x: rect.left + rect.width / 2 + window.scrollX, // Коррекция на скроллинг страницы
          y: rect.top + rect.height / 2 + window.scrollY, // Коррекция на скроллинг страницы
        };
        
        const asteroids = match.itemsToDelete.filter(({type}) => type === 'type1')
        let asteroidsReward = 0
        let asteroidsRewardRS = 0
        let asteroidsRewardTime = 0
        const addAsteroids:Stone['alias'][] = []

        asteroids.forEach((a,i) => {
          if(duplicates.has(a.id)) return
          duplicates.add(a.id)
          const reward = asteroidExplosionsResults.shift() as AsteroidExplosionResult
          asteroidsReward += (reward.coins || 0)
          asteroidsRewardRS += (reward.rs || 0)
          asteroidsRewardTime += (reward.time || 0)
          if(reward.stone) addAsteroids.push(reward.stone)
        })
        const onEndCoinAnim = () => {
          setCoins(prev => ({ ...prev, local: prev.local + match.reward,remote:prev.remote + asteroidsReward}));
        }
        const onEndRSAnim = () => {
          setCombo(prev => prev + asteroidsRewardRS);
        }
        const onEndTimeAnim = () => {
          addTime(asteroidsRewardTime * 1000)
        }
        // Вызываем spawnAnimatedReward с типом и рассчитанной позицией
        const summ = match.reward + asteroidsReward
        if (summ) spawnAnimatedReward('coin',summ, position,onEndCoinAnim);
        if(asteroidsRewardRS) spawnAnimatedReward('rs',asteroidsRewardRS, position,onEndRSAnim);
        if(asteroidsRewardTime) spawnAnimatedReward('time',asteroidsRewardTime, position,onEndTimeAnim);
        addAsteroids.forEach((alias) => {
          const onEnd = () => {
            setstones(prev => {
              if(!prev) return prev
              return prev.map((stone) => {
                if(stone.alias !== alias) return stone
                return {
                  ...stone,
                  active:1,
                  quantity:stone.quantity + 1
                }
              })
            })
          }
          spawnAnimatedReward(alias,null, position,onEnd)
        })
      }
    });

    // Удаляем элементы после завершения анимации
    setTimeout(() => {
      const gridAfterRemoval = updatedGrid.map(row =>
        row.map(cell => (cell.toBeDeleted ? { ...cell, type: 'empty', toBeDeleted: false} : cell))
      );
      setGrid(gridAfterRemoval as GridItem[][]);
      // setblocked(false)
    }, 250); // Время анимации удаления
  }

  function findMatches(grid: GridItem[][]) {
    const newGrid = [...grid];
    const numRows = newGrid.length;
    const numCols = newGrid[0].length;
  
    const matches: Match[] = [];
    let reward = 0;
    let combo = 0;
  
    function calculateCenter(streak: GridItem[], startRow: number, startCol: number, isHorizontal: boolean) {
      const length = streak.length;
      if (isHorizontal) {
        return { row: startRow, col: startCol + Math.floor(length / 2) };
      } else {
        return { row: startRow + Math.floor(length / 2), col: startCol };
      }
    }
  
    function extendStreakWithType1(streak: GridItem[], startRow: number, startCol: number, isHorizontal: boolean) {
      if (isHorizontal) {
        // Расширяем ряды по горизонтали
        let colLeft = startCol - 1;
        let colRight = startCol + streak.length;
        
        // Влево
        while (colLeft >= 0 && newGrid[startRow][colLeft].type === 'type1') {
          streak.unshift(newGrid[startRow][colLeft]);
          colLeft--;
        }
        
        // Вправо
        while (colRight < numCols && newGrid[startRow][colRight].type === 'type1') {
          streak.push(newGrid[startRow][colRight]);
          colRight++;
        }
      } else {
        // Расширяем ряды по вертикали
        let rowUp = startRow - 1;
        let rowDown = startRow + streak.length;
        
        // Вверх
        while (rowUp >= 0 && newGrid[rowUp][startCol].type === 'type1') {
          streak.unshift(newGrid[rowUp][startCol]);
          rowUp--;
        }
        
        // Вниз
        while (rowDown < numRows && newGrid[rowDown][startCol].type === 'type1') {
          streak.push(newGrid[rowDown][startCol]);
          rowDown++;
        }
      }
    }
  
    // Проверка по горизонтали
    for (let row = 0; row < numRows; row++) {
      let currentStreak: GridItem[] = [];
      for (let col = 0; col < numCols; col++) {
        const currentItem = newGrid[row][col];
        if (currentStreak.length === 0 || (currentItem.type === currentStreak[0].type && currentItem.type !== 'empty' && !currentItem.toBeDeleted)) {
          currentStreak.push(currentItem);
        } else {
          if (currentStreak.length >= 3) {
            
            const streakReward = rewardByStreak(currentStreak.length, currentStreak[0].type);
            // Расширение ряда элементами типа 'type1'
            extendStreakWithType1(currentStreak, row, col - currentStreak.length, true);
            reward += streakReward;
            combo++;
  
            const center = calculateCenter(currentStreak, row, col - currentStreak.length, true);
            matches.push({
              itemsToDelete: [...currentStreak],
              reward: streakReward,
              combo,
              center,
            });
          }
          currentStreak = [currentItem];
        }
      }
  
      if (currentStreak.length >= 3) {
        const streakReward = rewardByStreak(currentStreak.length, currentStreak[0].type);
        
        extendStreakWithType1(currentStreak, row, numCols - currentStreak.length, true);
        reward += streakReward;
        combo++;
  
        const center = calculateCenter(currentStreak, row, numCols - currentStreak.length, true);
        matches.push({
          itemsToDelete: [...currentStreak],
          reward: streakReward,
          combo,
          center,
        });
      }
    }
  
    // Проверка по вертикали
    for (let col = 0; col < numCols; col++) {
      let currentStreak: GridItem[] = [];
      for (let row = 0; row < numRows; row++) {
        const currentItem = newGrid[row][col];
        if (currentStreak.length === 0 || (currentItem.type === currentStreak[0].type && currentItem.type !== 'empty' && !currentItem.toBeDeleted)) {
          currentStreak.push(currentItem);
        } else {
          if (currentStreak.length >= 3) {
            const streakReward = rewardByStreak(currentStreak.length, currentStreak[0].type);
            
            // Расширение ряда элементами типа 'type1'
            extendStreakWithType1(currentStreak, row - currentStreak.length, col, false);
            reward += streakReward;
            combo++;
  
            const center = calculateCenter(currentStreak, row - currentStreak.length, col, false);
            matches.push({
              itemsToDelete: [...currentStreak],
              reward: streakReward,
              combo,
              center,
            });
          }
          currentStreak = [currentItem];
        }
      }
  
      if (currentStreak.length >= 3) {
        const streakReward = rewardByStreak(currentStreak.length, currentStreak[0].type);
        
        extendStreakWithType1(currentStreak, numRows - currentStreak.length, col, false);
        reward += streakReward;
        combo++;
  
        const center = calculateCenter(currentStreak, numRows - currentStreak.length, col, false);
        matches.push({
          itemsToDelete: [...currentStreak],
          reward: streakReward,
          combo,
          center,
        });
      }
    }
  
    return matches;
  }
  
  
  
  
  
  const fillEmptyCells = () => {
    // if(blocked) return
    // setblocked(true)
    const newGrid = grid.map(row => row.map(cell => ({ ...cell }))); // Создаем новую сетку
    let allEmpty = 0
    for (let col = 0; col < columns; col++) {
      let emptyCount = 0;
  
      for (let row = rows - 1; row >= 0; row--) {
        if (newGrid[row][col].type === 'empty') {
          emptyCount++;
        } else if (emptyCount > 0) {
          newGrid[row + emptyCount][col] = { ...newGrid[row][col] };
          newGrid[row][col] = { id: generateId(), type: 'empty' };
        }
      }
  
      // Заполнение верхних пустых ячеек
      for (let row = 0; row < emptyCount; row++) {
        newGrid[row][col] = {
          id: generateId(),
          type: getRandomItem(),
          initialTop: -(emptyCount - row) * cellSize, // Позиция выше поля
        };
      }
      allEmpty+=emptyCount
    }

    if(allEmpty === 0 ) return
    
    setGrid(newGrid);
    if(gameState !== 'game' ) {
      setblocked(false)
      return
    }
    setTimeout(() => {
      const matches = findMatches(newGrid);
      if (matches.length > 0) {
       handleRemoveCells(matches,newGrid)
      } else setblocked(false)
  }, 250); // Время, равное длительности анимации
  };
  
  const showCells = () => {
    return grid.reduce((items:(JSX.Element | null)[],row, rowIndex) => {
      const newRow = row.map((item, columnIndex) => {
        const isBlueSelected = (selectedStone?.alias === 'blue' && item.type === 'type1')
        if (item.type === 'empty') return null
        return (
            <motion.div
            key={item.id}
            className={`cell cell-${item.type} row-${rowIndex} col-${columnIndex} ${item.toBeDeleted ? 'to-be-deleted' : ''} ${selectedStone ? 'use-stone' : ''}`}
            layout
            style={{
              width: `${cellSize}px`,
              height: `${cellSize}px`,
              left: `${columnIndex * cellSize}px`,
              top: item.initialTop !== undefined ? item.initialTop : `${rowIndex * cellSize}px`, // Начальная позиция сверху
              backgroundImage: `url(/img/game-item/${item.type.charAt(item.type.length - 1)}.png)`,
              filter:isBlueSelected ? "brightness(0.2)" : 'none'
            }}
            initial={{ opacity: 0, top: item.initialTop ?? `${rowIndex * cellSize}px` }}
            animate={{ opacity: 1, top: `${rowIndex * cellSize}px` }} // Анимация падения до целевой позиции
            transition={{ duration: 0.25 }}
            exit={{opacity:0 ,left: `${(item.center?.col || columnIndex) * cellSize}px`,top: `${(item.center?.row || rowIndex) * cellSize}px`}}
            // onMouseDown ={() => handleTouchStart(rowIndex, columnIndex,item.type)} // Обработка начала свайпа
            onTouchStart={() => {
              if(isBlueSelected) {
                tg.HapticFeedback.notificationOccurred('error')
                return
              }
              handleTouchStart(rowIndex, columnIndex,item.type)
            }} // Обработка начала свайпа
            // onMouseMove={(e) => handleTouchMove(e.clientX,e.clientY)} // Обработка перемещения пальца
            onTouchMove={(e) => handleTouchMove(e.touches[0].clientX,e.touches[0].clientY)} // Обработка перемещения пальца
            // onMouseUp={handleTouchEnd} // Обработка окончания свайпа
            onTouchEnd={handleTouchEnd} // Обработка окончания свайпа
            // onAnimationEnd={() => {
            //   if(blocked && !item.initialTop) return
            //   setblocked(true)
            // }}
            // onAnimationComplete={() => {
            //   console.log('UNBLOCK');
            //   setblocked(false)
            // }}
          />
        )
      })

      // items.concat(newRow)

      return [...items,...newRow]
    },[]);
  };

  useEffect(() => {
    // if(blocked) return
    const timer = setTimeout(() => {
      fillEmptyCells(); // Заполнение пустых ячеек
    }, 250); // Задержка, равная времени анимации
    
    return () => clearTimeout(timer); // Очистка таймера при размонтировании или обновлении эффекта
  }, [grid]); // Зависимость от grid
  

  return { grid, showCells };
};

export default useGame;

import GridItem, { ItemType } from "types/grid-item";
import { Stone } from "types/user-data";

interface StoneInfo {
  destroyCells: (
    startRow: number,
    startCol: number,
    grid: GridItem[][],
    type: ItemType
  ) => GridItem[][];
  image: string;
  shadow: string;
  timeStop?:number
}

const stonesInfo: Record<Stone["alias"], StoneInfo> = {
  red: {
    image: "img/game-brilliant.png",
    shadow: "#c00",
    destroyCells: (startRow, startCol, grid) => {
      return grid.map((row, rowIndex) =>
        rowIndex === startRow
          ? row.map((cell) => ({ ...cell, type: "empty" })) // Уничтожаем все ячейки в строке
          : row
      );
    },
  },
  orange: {
    image: "img/game-brilliant2.png",
    shadow: "#c96700",

    destroyCells: (startRow, startCol, grid) => {
      return grid.map((row, rowIndex) =>
        row.map((cell, colIndex) =>
          // Уничтожаем по двум диагоналям (главная и побочная)
          (rowIndex === startRow && colIndex === startCol) ||
          rowIndex - colIndex === startRow - startCol ||
          rowIndex + colIndex === startRow + startCol
            ? { ...cell, type: "empty" }
            : cell
        )
      );
    },
  },
  yellow: {
    image: "img/game-brilliant3.png",
    shadow: "#c59807",
    destroyCells: (startRow, startCol, grid, type: ItemType) => {
      return grid.map((row) =>
        row.map((cell) =>
          // Если тип ячейки совпадает с выбранным цветом, заменяем ее на пустую
          cell.type === type ? { ...cell, type: "empty" } : cell
        )
      );
    },
    // destroyCells: (startRow, startCol, grid) => {
    //   return grid.map((row) =>
    //     row.map((cell, colIndex) => (colIndex === startCol ? {...cell,type:'empty'} : cell))
    //   );
    // },
  },
  blue: {
    image: "img/game-brilliant4.png",
    shadow: "#2C31D5",
    destroyCells: (startRow, startCol, grid) => {
      const targetCell = grid[startRow][startCol];
      const targetColor = targetCell.type;

      // Копируем исходную сетку
      const newGrid = grid.map((row) => row.map((cell) => ({ ...cell })));

      // Функция для проверки границ
      const isValidPosition = (row: number, col: number) =>
        row >= 0 && row < grid.length && col >= 0 && col < grid[0].length;

      // Изменяем все соседние ячейки на цвет выбранной
      const directions = [
        [-1, 0], // Вверх
        [1, 0], // Вниз
        [0, -1], // Влево
        [0, 1], // Вправо
        [-1, -1], // Диагональ вверх-влево
        [-1, 1], // Диагональ вверх-вправо
        [1, -1], // Диагональ вниз-влево
        [1, 1], // Диагональ вниз-вправо
      ];

      directions.forEach(([dRow, dCol]) => {
        const newRow = startRow + dRow;
        const newCol = startCol + dCol;

        if (isValidPosition(newRow, newCol)) {
          newGrid[newRow][newCol].type = targetColor;
        }
      });

      // Возвращаем новую сетку с измененными соседними ячейками
      return newGrid;
    },
  },
  green: {
    image: "img/game-brilliant5.png",
    shadow: "#22C407",
    destroyCells: (startRow, startCol, grid) => {
     return grid
    },
    timeStop:10
  },
  violet: {
    image: "img/game-brilliant6.png",
    shadow: "#BD00FF",
    destroyCells: (startRow, startCol, grid) => {
      return grid.map((row, rowIndex) =>
        row.map((cell, colIndex) =>
          // Уничтожаем, если ячейка попадает в квадрат 3x3 вокруг (startRow, startCol)
          Math.abs(rowIndex - startRow) <= 2 &&
          Math.abs(colIndex - startCol) <= 2
            ? { ...cell, type: "empty" }
            : cell
        )
      );
    },
  },
};

export default stonesInfo;

import Boost from "pages/Boost/Boost";
import Friends from "pages/Friends/Friends";
import Game from "pages/Game/Game";
import Home from "pages/Home/Home";
import Loading from "pages/Loading/Loading";
import Race from "pages/Race/Race";
import Tasks from "pages/Tasks/Tasks";
import Teams from "pages/Teams/Teams";
import Training from "pages/Training/Training";
import { RouteObject } from "react-router-dom";
import { navigationTitles } from "types/locale";
import Icon from "UI/Icon";

type Route = {
  route:RouteObject
  titleKey?: keyof navigationTitles
  navBarIcon?:string
  parentRoute?:string
}

const routes:Route[] = [
    {
      route:{
        path: "/",
        element: <Loading />,
      },
    },
    {
      route:{
        path: "/training",
        element: <Training />,
      },
    },
    {
      route:{
        path: "/boost",
        element: <Boost />,
      },
      parentRoute:'/home'
    },
    {
      route:{
        path: "/game",
        element: <Game />,
      },
    },
    {
      route:{
        path: "/home",
        element: <Home />,
      },
      navBarIcon:'img/menu-icon.svg',
      titleKey:'play',

    },
    {
      route:{
        path: "/teams",
        element: <Teams />,
      },
      navBarIcon:'img/menu-icon2.svg',
      titleKey:'teams',

    },
    {
      route:{
        path: "/race",
        element: <Race />,
      },
      navBarIcon:'img/menu-icon3.svg',
      titleKey:'race',

    },
    {
      route:{
        path: "/friends",
        element: <Friends />,
      },
      navBarIcon:'img/menu-icon4.svg',
      titleKey:'friends',

    },
    {
      route:{
        path: "/tasks",
        element: <Tasks />,
      },
      navBarIcon:'img/menu-icon5.svg',
      titleKey:'tasks',

    },
  ]
  export default routes
import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useState,
} from "react";
import formatTime from "utils/format-time";

interface Props
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  time: number;
  onEnd?:() => void
}

const TimerText = (props: Props) => {
  const [currentTime, setCurrentTime] = useState(props.time);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((prev) => {
        if(prev-1 <= 0) {
          setTimeout(() => {if(props.onEnd) props.onEnd()},1000)
          clearInterval(interval);
          return 0
        } else return prev-1
      })
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <span {...props}>{formatTime(currentTime)}</span>;
};

export default TimerText;

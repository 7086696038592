import Lang from "types/lang"
import tg from "./tg"

export default (avalaibleLangs:Lang['code'][]) => {
    avalaibleLangs = avalaibleLangs.map(l => l.toLowerCase())
    let tgLang = tg.initDataUnsafe.user?.language_code || ''
    if (!avalaibleLangs.includes(tgLang)) tgLang = 'en'

    const localLang = localStorage.getItem('lang')
    return localLang || tgLang
}
import getTasks from 'api/get-tasks'
import { useContext, useEffect, useState } from 'react'
import TasksResponce from 'types/task-response'
import PageWrapper from 'UI/PageWrapper'
import TaskCard from './TaskCard'
import TaskCardDaily from './TaskCardDaily'
import appContext from 'utils/app-context'

type Props = {}

const Tasks = (props: Props) => {
    const [tasks, settasks] = useState<TasksResponce | null>(null)
    const {locale} = useContext(appContext)
    const updateTasks = async () => {
        const res = await getTasks()
        if(res) settasks(res) 
    }

    useEffect(() => {
        updateTasks()
    }, [])
    if(!locale) return null
    const text = locale.tasks
  return (
    <PageWrapper className='m-page'>
         <div className="tasks">
        <div className="container">
            <div className="tasks__top">
                <div className="tasks__coin">
                    <img src="img/coin.svg" alt=""/>
                </div>
                <div className="tasks__title">
                {text.title}
                </div>

            
            </div>
            <div className="tasks__content">
                <div className="tasks-group">
                    <div className="m-subtitle">
                        {text.daily_title}
                    </div>
                    <div className="tasks-group__items" id="daily-reward">
                        <TaskCardDaily daily={tasks?.everyday} onTimerEnd={updateTasks}/>
                    </div>
                    <div className="tasks-group__items" id="daily-task-list">
                    {tasks && tasks.tasks[0].map((t) => <TaskCard task={t} handleClick={updateTasks}/>)}
                    </div>
                </div>
                <div className="tasks-group">
                    <div className="m-subtitle">
                    {text.main_title}
                    </div>
                    <div className="tasks-group__items" id="main-task-list">
                    {tasks && tasks.tasks[1].map((t) => <TaskCard task={t} handleClick={updateTasks}/>)}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="modal modal2 bonus-modal" id="bonusModal">
        <div className="modal__bg modal-bg _close-modal"></div>
        <div className="modal__dialog">
            <div className="m-card">
                <div className="m-card__inner">
                    <div className="bonus-modal__top">
                        <div className="bonus-modal__info">
                            <div className="bonus-modal__title">
                                Ежедневный бонус
                            </div>
                            <div className="bonus-modal__desc">
                                Заходите каждый день <br/>
                            и получайте больше бонусов
                            </div>
                        </div>
                        <div className="bonus-modal__items" id="daily-reward-icons">
                        </div>
                    </div>

                    <div className="bonus-slider">
                        <div className="bonus-slider__wrap">
                            <div className="swiper-container">
                                <div className="swiper-wrapper" id="daily-reward-slider">
                                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="bonus-modal__btn m-btn m-btn-big m-btn-red" 
                    // onclick="claimDailyReward()" 
                    id="daily-reward-btn">
                        <span>Получить</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </PageWrapper>
  )
}


export default Tasks
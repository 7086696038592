import tg from "utils/tg";
import UserData from "types/user-data";
import AsteroidExplosionResult from "types/asteroid-explosion-result";
const tg_id = tg.initDataUnsafe.user?.id || 1745939501;
interface ErrorResponse {
  error: string;
}
export default async ():Promise<AsteroidExplosionResult[] | null | ErrorResponse> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "start_game";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};

import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from 'routes'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {}

const Navigation = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {locale,user} = useContext(appContext)
  const currentLoc = routes.find(({route:{path}}) => location.pathname === path)
  const titles = locale?.navigationTitles
  if (!currentLoc?.parentRoute && !currentLoc?.navBarIcon || !titles) return null
  

  return (
      <div className="menu">
      {routes.map(({route:{path},navBarIcon,titleKey,parentRoute}) => {
        if (!navBarIcon || !titleKey) return null
        const handleClick = () => {
          navigate(path || '')
          tg.HapticFeedback.selectionChanged()
        }
        const iscurrentRoute = location.pathname === path || parentRoute === location.pathname
        return (
          <a className={`menu__btn ${iscurrentRoute && '_active'}`} key={path} onClick={handleClick}>
            {(path === '/tasks' && !!user?.tasks_active) && <span className='tasks-counter'>{user?.tasks_active}</span>}
            <img src={navBarIcon} />
          <span className={`sidebar__text ${iscurrentRoute && 'sidebar__text_first'}`}>{titles[titleKey]}</span>
          {/* <span className={`sidebar__text ${iscurrentRoute && 'sidebar__text_first'}`}>{titleKey}</span> */}
        </a>
        )
      })}
    </div>
  )
}

export default Navigation
import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import formatNumber from 'utils/format-number';
import Icon from 'UI/Icon';
import HomeFarming from './components/HomeFarming';
import upgradeStone from 'api/upgrade-stone';
import tg from 'utils/tg';
import { toast } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import HomeAbout from './components/HomeAbout';
import { useNavigate } from 'react-router-dom';
import HomeSetLangModal from './components/HomeSetLangModal';
type Props = {}
const Home = (props: Props) => {
    const navigate = useNavigate()
    const {user,updateUserInfo,locale,setModal} = useContext(appContext)
    const [currrentSlide, setcurrrentSlide] = useState(0)
    const [about, setabout] = useState(false)
    useEffect(() => {
        tg.BackButton.hide();
        tg.bottomBarColor = '#141720'
    }, [])
    if(!user || !locale) return null
    const {stones,balance,balance_rs} = user
    const currentStone = stones[currrentSlide]

    const stoneUpgradeCost = Math.max((currentStone?.upgrade_cost || 0) - Number(balance),0)
    const isMaxLvl = (currentStone?.level || 0) >= 10
    const max = (<div className="user-info__num red-text" id="for_next_level">MAX</div>)

    const handleUpgradeStone = async () => {
        if(stoneUpgradeCost !== 0 || isMaxLvl) {
            tg.HapticFeedback.notificationOccurred('error')
            return
        }
        const res = await upgradeStone(currentStone.alias)
        if(res) {
            await updateUserInfo()
            toast(`${text.upgrade_toast_text} ${currentStone?.alias} Stone`,{icon:(
                <img className='animate-icon' src={`img/stones/${currentStone?.alias}/${currentStone?.level+1}.png`} alt=""/>
            )});
            tg.HapticFeedback.notificationOccurred('success')
        }
    }
    const text = locale.home
   
    return (
    <PageWrapper className='m-page'>
        <AnimatePresence>
            {about && <HomeAbout close={() => setabout(false)}/>}
        </AnimatePresence>
      <div className="main" >
        <div className="container">
            <div className="main-header">
                <div className="main-header__user">
                    <img className="main-header__user-img" src={user?.avatar} alt="" id="user-avatar"/>
                    <span className="text16" id="user-name">{user?.first_name}</span>
                </div>
                <div className="main-header__action">
                    <div className="hint">
                        <button className="hint__btn" onClick={() => setabout(true)}>
                            <img src="img/hint-icon2.svg" alt=""/>
                        </button>
                    </div>
                    <button className="main-header__wallet" onClick={() => navigate('/boost')}>
                        <img src="img/wallet.svg" alt=""/>
                    </button>
                    <div className="lang" onClick={() => setModal({content:<HomeSetLangModal/>})}>
                        <div className="lang__show">
                            <span className="text16">{locale.code}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="user-info m-card">
                <div className="m-card__inner">
                    <div className="user-info__wrap _flex">
                        <div className="user-info__balance _flex">
                            <img src="img/coin.svg" alt=""/>
                            <span className="text15" id="balance">
                                {formatNumber(user?.balance)}
                            </span>
                        </div>
                        <div className="user-info__upgrade" id="for_next_level_block">
                            <div className="user-info__num " id="for_next_level">
                            {isMaxLvl ? max : stoneUpgradeCost }
                            </div>
                            <div className="user-info__next">
                                {text.for_next_lvl}
                            </div>
                            {!currentStone.active && <span className='stone-not-active'>{text.stone_not_found}</span>}
                        </div>
                        <button onClick={handleUpgradeStone} className={`user-info__btn ${stoneUpgradeCost === 0 && !isMaxLvl ? '_light' : ''}`} id="upgrade-stone">
                            <img className="_view1" src="img/arrow-top2.svg" alt=""/>
                            <img className="_view2" src="img/arrow-top3.svg" alt=""/>
                        </button>
                    </div>
                </div>
            </div>


            <div className="main-slider">
                <div className="main-slider__top">
                    <span id="stone-name">{currentStone?.alias} Stone</span>
                    <span className="stone-level"> ({currentStone?.level}/10)</span>
                </div>
                <div className="swiper-container">
                    {/* <div className="swiper-wrapper" id="stones-slider">
                    </div> */}
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={60}
                        totalSlides={user?.stones.length || 0}
                        className='swiper-wrapper'
                    >
                        <SlideProccessing onChange={setcurrrentSlide}/>
                        <Slider>
                            {user?.stones.map(({level,alias},i) => (
                                <Slide index={i} onFocus={() => console.log(alias)} key={i}>
                                <div className="main-slider__img" style={level === 0 ? {filter:'grayscale(1)',opacity:0.5} : {}}>
                                    <img src={`img/stones/${alias}/${Math.max(level,1)}.png`} alt=""/>
                                </div>
                                </Slide>
                            ))}
                        
                        </Slider>
                        <div className="swiper-nav">
                        <ButtonBack className="swiper-button swiper-button-prev">
                            <img src="img/prev.svg" alt=""/>
                        </ButtonBack>
                        <ButtonNext className="swiper-button swiper-button-next">
                            <img src="img/next.svg" alt=""/>
                        </ButtonNext>
                    </div>
                    </CarouselProvider>
                </div>
            </div>

        </div>

        <div className="main__bottom">
            <div className="main-action">
                <div className="main-action__info">
                    <div className="main-action__title">{text.balance}</div>
                    <div className="main-action__num" >
                        <span id="balance-rs">{formatNumber(balance_rs)}</span> <Icon icon='rs'/>
                    </div>
                    <div className="main-action__num2"><span id="rs-per-hour">{user?.mining_per_hour}</span> RS / {text.hour_short}</div>
                </div>
                <div className="main-action__buttons">
                    <button onClick={() => navigate('/boost')} className="main-action__boost main-action__btn m-btn" style={{cursor:'pointer'}}>
                        <span>Boost</span>
                        <img src="img/rocket.svg" alt=""/>
                    </button>
                    <button
                    onClick={() => navigate('/game')} 
                    // onClick={() => window.location.href = '/game/index.html'} 
                    id="play-button" className="main-action__play main-action__btn m-btn m-btn-white" style={{cursor:'pointer'}}>
                        <span>Play</span>
                    </button>
                </div>
            </div>

            <HomeFarming/>
        </div>
    </div>

    {/* <div className="m-card toast" id="stone-upgrade-toast">
        <div className="m-card__inner">
            <div className="toast-icon">
                <img id="stone-upgrade-toast-image" alt=""/>
            </div>
            <p className="toast-text">Вы прокачали <span id="stone-upgrade-toast-title"></span>
            </p>
        </div>
    </div> */}
    </PageWrapper>
  )
}

const SlideProccessing = ({onChange}:{onChange:(s:number) => void}) => {
    const {subscribe,getStoreState,unsubscribe} = useContext(CarouselContext);
    useEffect(() => {
        const callChange = () => {
            const {currentSlide} = getStoreState()
             onChange(currentSlide)
         }
        subscribe(callChange)
        return () => unsubscribe(callChange)
    }, [])
    return(<></>)
}

export default Home
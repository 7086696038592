import { useContext, useEffect, useRef, useState } from "react";
import Product from "types/product";
import { SendTransactionRequest, TonConnectUI, useTonConnectUI } from "@tonconnect/ui-react";
import buyProduct from "api/buy-product";
import createTransaction from "api/create-transaction";
import { unsubscribe } from "diagnostics_channel";
import tg from "utils/tg";
import appContext from "utils/app-context";

export default (product?:Product) => {
  const [TonConnectUI] = useTonConnectUI()
  const [waitingForConnect, setwaitingForConnect] = useState(false)
  const [loading, setloading] = useState(false)
  const [success, setsuccess] = useState(false)
  const {user} = useContext(appContext)

  let unsubscribe = useRef<() => void>(null).current

  const handleBuyProduct = async (onSuccess?:() => void,params?:{withoutTonPurchase:boolean}) => {
    if(!product) return
    setloading(true)
    try {
        if (!TonConnectUI.connected) {
          TonConnectUI.openModal();
          setwaitingForConnect(true)
          setloading(false)
          return
        } else {
          const result = await createTransaction(product.id)
          console.log("🚀 ~ handleBuyProduct ~ result:", result)
          if(!result) throw new Error('Error during the creation of transaction')
          const transaction:SendTransactionRequest = {
            messages: [
                {
                    address: result.address,
                    amount: String(result.amount * 1000000000),
                }
            ],
              validUntil:Math.floor(Date.now() / 1000) + result.seconds,
            }
            console.log("🚀 ~ handleTonTransaction ~ transaction:", transaction)
            const TransactionRes = await TonConnectUI.sendTransaction(transaction)
        }
      const result = await buyProduct(product.id)
      console.log("🚀 ~ handleBuyProduct ~ result:", result)
      if(result?.result) {
        setsuccess(true)
        tg.HapticFeedback.notificationOccurred('success')
        if(onSuccess) onSuccess()
      } else throw new Error('Something went wrong')
    } catch (error) {
      console.log("🚀 ~ handleBuyProduct ~ error:", error)
      tg.showAlert(`Something went wrong:\n${error}`)
    }
    setloading(false)
  };
 
  useEffect(() => {
    const checkFromBuyProduct = () => {
      if(TonConnectUI.connected && waitingForConnect) {
        setwaitingForConnect(false)
        handleBuyProduct()
      }
    }

    if(unsubscribe) unsubscribe()
    unsubscribe = TonConnectUI.onStatusChange(checkFromBuyProduct)
    return () => {
      if(unsubscribe) unsubscribe()
    }
  }, [waitingForConnect])

  return {loading,success,handleBuyProduct}
}
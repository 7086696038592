import React, { useContext } from 'react'
import PageWrapper from 'UI/PageWrapper'
import placeholder from 'assets/images/placeholder-race.png'
import appContext from 'utils/app-context'

type Props = {}

const Race = (props: Props) => {
  const {locale} = useContext(appContext)
  if(!locale) return null
  return (
    <PageWrapper className='placeholder-page'>
        <img src={placeholder} alt="" />
        <h1>{locale.navigationTitles.teams}</h1>
        <p>{locale.pagePlaceholder}</p>
    </PageWrapper>
  )
}

export default Race
import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import appContext from 'utils/app-context'
import ProductCard from './ProductCard'

type Props = {}

const BoostStones = (props: Props) => {
    const {products,locale} = useContext(appContext)
    if(!locale) return null
    const text = locale.boost
    return (
    <div className="boost-upgrades">
    <div className="m-subtitle">{text.stones}</div>
    <motion.div 
    variants={container}
    initial='hidden'
    animate='visible'
    className="boost-upgrades__items" id="product-list">
        {products && products.map((p,i) => (
           <ProductCard product={p} key={i}/>
        ))}
    </motion.div>
  </div>
  )
}
const container = {
    hidden: { },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.02,
        staggerChildren: 0.02
      }
    }
  }
    
  
export default BoostStones
import { useRef, useState, useEffect } from 'react';

const useDimensions = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        ref.current.removeAttribute('style')
        const { offsetWidth, offsetHeight } = ref.current;
        setDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    // Устанавливаем начальные размеры
    updateDimensions();

    // Обновляем размеры при изменении размера окна
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return { ref, dimensions };
};

export default useDimensions;

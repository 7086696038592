import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
type Responce  = {
  result:boolean
  stone:any
}
export default async ():Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "stone_upgrade";
    API_URL.searchParams.set("tg_id", String(tg_id));
    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};

import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const mediaPaths = [
  'img/loading.gif',
  'img/game-brilliant.png',
  'img/game-brilliant2.png',
  'img/game-brilliant3.png',
  'img/game-brilliant4.png',
  'img/game-brilliant5.png',
  'img/game-brilliant6.png',
  'img/galaxy.png',
  'img/animation/sprite.png',
];

const GameLoading = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadMedia = async () => {
      try {
        // Загружаем каждое изображение с задержкой
        await Promise.all(
          mediaPaths.map((path) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = path;
              img.onload = () => setTimeout(() => resolve(path), 1000); // Полсекунды задержки перед разрешением
              img.onerror = () => setTimeout(() => resolve(path), 1000); // Полсекунды задержки при ошибке
            });
          })
        );

        // После завершения загрузки
        setIsLoading(false);
      } catch (error) {
        console.error('Ошибка загрузки медиа:', error);
        setIsLoading(false); // Скрываем экран загрузки при ошибке
      }
    };

    loadMedia();
  }, []);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          exit={{ opacity: 0 }}
          className='loading-screen'
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
        >
          <img src='img/loading.gif' alt='Loading...' />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GameLoading;

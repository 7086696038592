import { AnimatePresence, motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
import appContext from "utils/game-context";
import { bodyVariants } from "utils/modal-animation";
import tg from "utils/tg";

const GameModal = ({finishLoading}:{finishLoading:boolean}) => {
  const { coins, combo ,gameState,setgameState,startTimer,setCoins,setCombo,availableGames} = useContext(appContext);
  const nav = useNavigate()

  const GoToMenu = () => nav(-1)
  const GoToStore = () => nav('/boost?store=true')

  const playAgain = () => {
    console.log("RESTART GAME");
    
    if(!availableGames){
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setgameState('game')
    startTimer(90000)
    setCoins({local:0,remote:0})
    setCombo(0)
  }

  return (
    <AnimatePresence initial={false}>
      {gameState == "finish" && (
        <motion.div 
        {...bodyVariants}
        className={`modal finish-modal ${finishLoading && 'loading'}`}>
          <div className="modal__bg modal-bg _close-modal"></div>
          <div className="modal__dialog">
            <div className="m-card">
          {finishLoading && <svg className="loading-icon" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>}
              <div className="m-card__inner">
                <div className="modal__title">Игра окончена!</div>
                <img
                  className="finish-modal__img"
                  src="img/galaxy.png"
                  alt=""
                />
                <div className="finish-modal__desc">
                {availableGames === 0 && <div className="text14">
                                Попытки закончились! <br/>
                        Дополнительные можно приобрести в улучшениях
                            </div>}
                </div>

                <div className="props">
                  <div className="prop-item">
                    <div className="prop-item__title3">Очков</div>
                    <div className="prop-item__desc">
                      <img src="img/coin.svg" alt="" />
                      <span id="finish-modal-score">{coins.local + coins.remote}</span>
                    </div>
                  </div>
                  <div className="prop-item">
                    <div className="prop-item__title3">RS</div>
                    <div className="prop-item__desc">
                      <img src="img/rs.svg" alt="" />
                      <span id="finish-modal-score">{combo}</span>
                    </div>
                  </div>
                </div>
                <div className="finish-modal__nav">
                  <button className="finish-modal__btn m-btn m-btn-white-outline"  onClick={GoToMenu}>
                    <span>В меню</span>
                  </button>
                  <button 
                  style={{opacity:availableGames ? 1 : 0.5}}
                  className="finish-modal__btn m-btn m-btn-white-outline" onClick={playAgain}>
                    <img src="img/refresh.svg" alt="" />
                    <span>Еще раз</span>
                  </button>
                </div>
                <div className="finish-modal__nav">
                  <button style={{gridColumn:'span 2'}} className="finish-modal__btn m-btn m-btn-red" onClick={GoToStore}>
                    <span>Магазин</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {gameState === 'not-available' && (
        <motion.div
        {...bodyVariants}
        className="modal finish-modal">
        <div className="modal__bg modal-bg _close-modal"></div>
        <div className="modal__dialog">
            <div className="m-card">
                <div className="m-card__inner">
                    <div className="modal__title">
                        Не хватает энергии!
                    </div>
                    <img className="finish-modal__img" src="img/galaxy.png" alt=""/>
                    <div className="finish-modal__desc">
                            <div className="text14">
                                Попытки закончились! <br/>
                        Дополнительные можно приобрести в улучшениях
                            </div>
                        </div>
    
                    <div className="finish-modal__nav3">
                        <button className="finish-modal__btn m-btn m-btn-white-outline" onClick={GoToMenu}>
                            <span>В меню</span>
                        </button>
                       
                    </div>
                    <div className="finish-modal__nav">
                  <button style={{gridColumn:'span 2'}} className="finish-modal__btn m-btn m-btn-red" onClick={GoToStore}>
                    <span>Магазин</span>
                  </button>
                </div>
                </div>
            </div>
        </div>
    </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GameModal;

import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import appContext from 'utils/game-context'

const GameStats = () => {
    const {coins,time,isPaused,combo,gameState,availableGames} = useContext(appContext)
    const animate = typeof isPaused === 'number' && gameState === 'game'
  return (
    <div className="game-top m-card">
        <div className="m-card__inner">
            <div className="game-top__wrap _flex-between">
                <div className="game-top__sum _flex">
                    <img src="img/coin.svg" alt=""/>
                    <span id="balance-display">{coins.local + coins.remote}</span>
                </div>
                <div className="game-top__time" id="timer-container">
                    <motion.span 
                    animate={{opacity:animate ? [0,1,0] : 1}}
                    transition={{repeat:Infinity}}
                    id="timer-display">{formatTime(time)}</motion.span>
                </div>
                <div className="game-top__combo text16 medium-text">
                <p id="rs-display" style={{display:'flex',alignItems:'center',gap:5}}>{combo} <img src="img/rs.svg" alt="" /></p>
                    <span>Осталось игр:{availableGames}</span>
                </div>
            </div>
        </div>
    </div>
)}
const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000); // Переводим миллисекунды в секунды
    const minutes = Math.floor(totalSeconds / 60); // Вычисляем минуты
    const seconds = totalSeconds % 60; // Оставшиеся секунды
  
    const formattedMinutes = String(minutes).padStart(2, '0'); // Добавляем ведущие нули для минут
    const formattedSeconds = String(seconds).padStart(2, '0'); // Добавляем ведущие нули для секунд
  
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  
export default GameStats
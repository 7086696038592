function formatTime(seconds:number) {
  seconds = Math.floor(seconds)
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = [
      hours.toString().padStart(1, '0'),
      minutes.toString().padStart(2, '0'),
      remainingSeconds.toString().padStart(2, '0')
  ].join(':');

  return formattedTime;
}
export default formatTime
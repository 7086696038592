import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Button from "UI/Button";
import PageWrapper from "UI/PageWrapper";
import "./Training.css";
import { useNavigate } from "react-router-dom";
import appContext from "utils/app-context";

type Props = {};

const Training = (props: Props) => {
  const [stage, setstage] = useState(0);
  const navigate = useNavigate()
  const {locale} = useContext(appContext)
  
  const paginate = (n:number) => {
    const next = stage + n
    if(!stages[next]) navigate('/home')
    else setstage(next)
  } 
  useEffect(() => {
    const training = localStorage.getItem('training')
    localStorage.setItem('training','true')
    if(training) navigate('/home')
  }, [])
  if(!locale) return null
  const stages = [
    {
      title: locale.training.FirstScreen.title_html,
      text: locale.training.FirstScreen.desc
    },
    {
      title: locale.training.SecondScreen.title_html,
      text: locale.training.SecondScreen.desc
    },
    {
      title: locale.training.ThirdScreen.title_html,
      text: locale.training.ThirdScreen.desc
    },
    {
      title: locale.training.FourScreen.title_html,
      text: locale.training.FourScreen.desc
    },
  ];
  return (
    <PageWrapper className="training-page">
      <AnimatePresence mode="wait">
        <motion.div {...variants} key={stage} className="stage-screen">
          <h2 dangerouslySetInnerHTML={{ __html: stages[stage].title }} />
          <p>{stages[stage].text}</p>
          <div
            className="stage-img"
            style={{ backgroundImage: `URL(img/training/${stage + 1}.png)` }}
          >
            {/* <img src={} alt="" /> */}
          </div>
        </motion.div>
      </AnimatePresence>
      <ul className="stage-dots">
        {stages.map((_, i) => (
          <span className={i === stage ? "current" : ""} />
        ))}
      </ul>
      <Button className="m-btn-red" onClick={() => paginate(1)}>
        {locale.training.next}
      </Button>
    </PageWrapper>
  );
};

const variants: HTMLMotionProps<"div"> = {
  initial: {
    x: "100%",
  },
  animate: {
    x: "0%",
  },
  exit: {
    x: "-100%",
  },
  transition: {
    duration: 0.2,
    ease: "circInOut",
  },
};

export default Training;

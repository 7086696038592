import { motion } from 'framer-motion'
import usePurchase from 'hooks/use-purchase'
import React, { useContext } from 'react'
import Product from 'types/product'
import Icon from 'UI/Icon'
import appContext from 'utils/app-context'

type Props = {
    product:Product
}

const ProductCard = ({product}: Props) => {
    const {title,shadow_color,price,image} = product
    const {locale} = useContext(appContext)
    const {handleBuyProduct,loading,success} = usePurchase(product)
    if(!locale) return null
    const text = locale.boost
    return (
    <motion.div
            variants={item}
            className="m-card boost-card3 @@mClass">
            <div className="m-card__inner">
                <div className="boost-card3__title">
                    {title}
                </div>
                <div className="boost-card3__wrap">
                    <div className="boost-card3__icon">
                        <img src={image} alt=""/>
                        <span className="boost-card3__shadow" style={{background:shadow_color}}></span>
                    </div>
                    <div className="boost-card3__info">
                        <div className="boost-card3__price">
                            <img src="img/coin2.svg" alt=""/>
                            <span className="text12">{price} TON</span>
                        </div>
                        <button className="boost-card3__btn m-btn m-btn-red" onClick={() =>  handleBuyProduct()}>
                            {loading ? <img className="loading-icon" src="img/loader.svg" alt="" /> : <span>{text.buy}</span>}
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
  )
}
const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  }
export default ProductCard
import claimFarming from 'api/claim-farming'
import startFarming from 'api/start-farming'
import useFarming from 'hooks/use-farming'
import React, { useContext } from 'react'
import appContext from 'utils/app-context'
import formatTime from 'utils/format-time'
import tg from 'utils/tg'

type Props = {}

const HomeFarming = (props: Props) => {
    const {user,updateUserInfo,locale} = useContext(appContext)
    const {coins,percent,seconds} = useFarming(user)
    const status = Number(user?.farming_status)
    if(!locale) return null
    const text = locale.home
    const handleFarmingButton = async () => {
        let res;
        switch (status) {
          case 0:
            res = await startFarming();
            tg.HapticFeedback.impactOccurred('medium')
            break;
    
          case 2:
            res = await claimFarming();
            tg.HapticFeedback.notificationOccurred('success')
            updateUserInfo();
            break;
    
          default:
            break;
        }
        updateUserInfo();
      };
      let btnText = text.start_farming
      let btnClass = 'm-btn-white'
      switch (status) {
        case 1:
         btnText = `${text.farming} ${coins.toFixed(4)}`
         btnClass = 'main-btn--progress'
         break;
         
         case 2:
           btnText = text.claim_farming
           btnClass = 'm-btn-red'
          break;
  
        default:
          break;
      }
  return (
    <button className={`main-btn m-btn ${btnClass}`} id="farming-btn" onClick={handleFarmingButton}>
    <span>{btnText}</span>
         {status === 1 && 
         <>
            <div style={{width:`${percent}%`}} className="main-btn__fill"></div>
          <div className="main-btn__time">
              <span id="farming-time">{formatTime(seconds)}</span>
          </div>
         </>
         }
    </button>
  )
}

export default HomeFarming
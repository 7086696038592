import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg';
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';

type Props = {}

const Friends = (props: Props) => {
    const {user,setModal,locale} = useContext(appContext)
    if(!locale) return null
    const text = locale.friends
  return (
    <PageWrapper className='m-page'>
        <div className="friends">
        <div className="container">
            <div className="friends__top">
                <div className="friends__title">
                    {text.title}
                </div>
                <div className="friends__desc" dangerouslySetInnerHTML={{__html:text.desc_html}}/>
            </div>

            <div className="friends__nav">
                <div className="friend-btn m-card">
                    <div className="m-card__inner">
                        <div className="friend-btn__text">
                            {text.invite}
                        </div>
                        <div className="friend-btn__price">
                            <img src="img/coin.svg" alt=""/>
                            <span>+10,000</span>
                        </div>
                    </div>
                </div>

                <div className="friend-btn m-card">
                    <div className="m-card__inner">
                        <div className="friend-btn__text">
                        {text.invite_with_premium}
                        </div>
                        <div className="friend-btn__price">
                            <img src="img/coin.svg" alt=""/>
                            <span>+20,000</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="friends__content">
                <div className="m-subtitle">
                    {text.your_f} (<span id="friends-count">{user?.friends}</span>):
                </div>
                <div className="friends__items" id="friends">
                   {user?.friends_list.map(({coins,name,photo,stones}) => (
                    <div className="friend-card m-card">
                    <div className="m-card__inner">
                        <div className="friend-card__img">
                            <img src={photo} alt=""/>
                        </div>
                        <div className="friend-card__info">
                            <div className="friend-card__title">
                                {name}
                            </div>
                            <div className="friend-card__desc">
                                {text.stones} {stones}/6
                            </div>
                        </div>
                        <div className="friend-card__price">
                            <img src="img/coin.svg" alt=""/>
                            <span>+{formatNumber(coins)}</span>
                        </div>
                    </div>
                </div>
                   ))}

                </div>
            </div>
        </div>

        <div className="friends__bottom">
            <button data-modal="friendModal" className="main-btn m-btn m-btn-red" onClick={() => setModal({content:<InviteFrendsModal/>})}>
                <span>{text.invite_friend_btn}</span>
            </button>
        </div>
    </div>
    </PageWrapper>
  )
}

const InviteFrendsModal = () => {
    const [copy, setcopy] = useState(false)
    const {setModal,locale} = useContext(appContext)
    const copyShareLink = (e:React.MouseEvent) => {
        setcopy(true)
        tg.HapticFeedback.notificationOccurred('success')
        navigator.clipboard.writeText(inviteLink)
        setTimeout(() => setcopy(false),1000)
      }
    const TelegramShare = () => {
        tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
    }
    if(!locale) return null
    const text = locale.friends
    return (
        <div style={{margin:"25px 0"}}>
         <div className="friend-modal__info">
                        <div className="friend-modal__title">
                            {text.modal_title}
                        </div>
                        <div className="friend-modal__desc">
                        {text.modal_desc}
                        </div>
                    </div>

                    <div className="friend-modal__nav">
                        <button className="friend-modal__btn m-btn m-btn-big m-btn-white-outline"
                        onClick={copyShareLink}
                        >
                            <span>{!copy ? text.copy : text.copied}</span>
                        </button>
                        <button className="friend-modal__btn m-btn m-btn-big m-btn-red" 
                        onClick={TelegramShare}
                        >
                            <span>{text.send}</span>
                        </button>
                        <button className="_close-modal friend-modal__btn m-btn m-btn-big" onClick={() => setModal(null)}>
                            <span>{text.close}</span>
                        </button>
                    </div>
        </div>
    )
}

export default Friends
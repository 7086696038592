import React from 'react';

type GridLinesProps = {
  cellSize: number;
  columns: number;
  rows: number;
};

const GridLines = ({ cellSize, columns, rows }: GridLinesProps) => {
  return (
    <>
      {/* Horizontal lines */}
      {Array.from({ length: rows - 1 }).map((_, rowIndex) => (
        <hr
        className='h'
          key={`h-line-${rowIndex}`}
          style={{
            position: 'absolute',
            top: `${(rowIndex + 1) * cellSize}px`,
            left: 0,
            width: '100%',
          }}
        />
      ))}

      {/* Vertical lines */}
      {Array.from({ length: columns - 1 }).map((_, colIndex) => (
        <hr
            className='v'
          key={`v-line-${colIndex}`}
          style={{
            position: 'absolute',
            top: 0,
            left: `${(colIndex + 1) * cellSize}px`,
            height: '100%',
          }}
        />
      ))}
    </>
  );
};

export default GridLines;
